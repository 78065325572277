<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <!-- Date Wise Search start-->
      <v-card v-if="searchDates">
        <v-card-title>Date Wise Search</v-card-title>
        <v-card-text>
          <v-row>
            <v-col offset="10">
              <v-btn
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class=""
                color="green"
                @click="switchSearch"
                >Salewise search</v-btn
              ></v-col
            >
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="3" xs="12" lg="3">
              <label class="text-md text-typo font-weight-bolder ms-1"
                ><span class="red--text"><strong>* </strong></span>From (Start
                date)</label
              >

              <v-menu
                ref="start_date_menu"
                v-model="start_date_menu"
                :close-on-content-click="false"
                :return-value.sync="start_date_menu"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_date"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="46"
                    placeholder="Start Date"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="green lighten-1"
                  header-color="primary"
                  v-model="start_date"
                  :max="end_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="start_date_menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.start_date_menu.save(start_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="3" xs="12" lg="3">
              <label class="text-md text-typo font-weight-bolder ms-1"
                ><span class="red--text"><strong>* </strong></span>To (End
                date)</label
              >

              <v-menu
                ref="end_date_menu"
                v-model="end_date_menu"
                :close-on-content-click="false"
                :return-value.sync="end_date_menu"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_date"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="46"
                    placeholder="To Date"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="green lighten-1"
                  header-color="primary"
                  v-model="end_date"
                  :min="start_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="end_date_menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.end_date_menu.save(end_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu> </v-col
            ><v-col
              ><v-btn
                @click="clearDateFilter"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-secondary
                  bg-light
                  py-3
                  px-6
                  mr-2
                  mt-10
                "
                >Clear</v-btn
              >

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                  mt-10
                "
                @click="searchByDates"
                >Search</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- Date Wise Search End-->

      <!-- Sale wise Search Start -->
      <v-card v-if="!searchDates">
        <v-card-title>Salewise search </v-card-title>
        <v-form ref="frm_sale_search">
          <v-card-text
            ><v-row>
              <v-col offset="10">
                <v-btn
                  icon
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class=""
                  color="green"
                  @click="switchSearch"
                  >Datewise search</v-btn
                ></v-col
              >
            </v-row>
            <v-row
              ><v-col cols="12" md="3">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  ><span class="red--text"><strong>* </strong></span>Auction
                  center</label
                >

                <v-select
                  :items="auctionCenters"
                  item-text="auction_center_name"
                  item-value="id"
                  hide-details="auto"
                  v-model="auction_center"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    select-style
                    mt-3
                  "
                  outlined
                  height="46"
                  :rules="[(v) => !!v || 'Auction center is required']"
                  placeholder="Select auction center"
                  @change="auctionChange"
                >
                  <template v-slot:selection="{ item }">
                    <v-text
                      name="c_name"
                      label
                      color="bg-default"
                      class="py-1 px-2 my-0"
                    >
                      <span class="text-caption ls-0"
                        >{{ item.auction_center_code }}-{{
                          item.auction_center_name
                        }}</span
                      >
                    </v-text>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  ><span class="red--text"><strong>* </strong></span
                  >Season</label
                >

                <v-select
                  :items="seasons"
                  item-value="id"
                  hide-details="auto"
                  v-model="season"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mt-3
                    mb-0
                  "
                  outlined
                  height="46"
                  @change="filterSeasonSale()"
                  single-line
                  :rules="[(v) => !!v || 'Season is required']"
                  placeholder="Select Season"
                >
                  <template v-slot:selection="{ item }">
                    <v-text
                      name="ss_name"
                      label
                      color="bg-default"
                      class="py-1 px-2 my-0"
                    >
                      <span class="text-caption ls-0">{{ item.name }}</span>
                    </v-text>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-text
                      name="ss_name"
                      label
                      color="bg-default"
                      class="py-1 px-2 my-0"
                    >
                      <span class="text-caption ls-0">{{ item.name }}</span>
                    </v-text>
                  </template>
                </v-select> </v-col
              ><v-col cols="12" sm="6" md="3" xs="12" lg="3">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  ><span class="red--text"><strong>* </strong></span>Sale
                  No</label
                >

                <v-select
                  :items="seasonSales"
                  item-value="id"
                  item-text="season_sale"
                  hide-details="auto"
                  v-model="seasonSale"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mt-3
                    mb-0
                  "
                  outlined
                  height="46"
                  single-line
                  :rules="[(v) => !!v || 'Sale No is required']"
                  placeholder="Select Sale No"
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      v-if="index === 0"
                      label
                      color="bg-default"
                      class="py-1 px-2 my-0"
                    >
                      <span class="text-white text-caption ls-0">{{
                        item.season_sale
                      }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ seasonSale.length - 1 }}
                      others)
                    </span>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item ripple @mousedown.prevent @click="toggleSale">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            seasonSale.length > 0 ? 'indigo darken-4' : ''
                          "
                        >
                          {{ iconSale }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select> </v-col
              ><v-col
                ><v-btn
                  @click="clearSaleFilter"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-secondary
                    bg-light
                    py-3
                    px-6
                    mr-2
                    mt-10
                  "
                  >Clear</v-btn
                >

                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                    mt-10
                  "
                  @click="searchBySales"
                  >Search</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>
      <!-- Sale wise Search End -->

      <!-- Table -->
      <v-card v-if="tableData.length > 0" class="mt-5">
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="tableData"
            class="table"
            mobile-breakpoint="0"
            hide-default-footer
            disable-pagination
          >
            Generated
            <template v-slot:[`item.sale_date`]="{ item }">
              <span>
                {{ formatDate(item.sale_date) }}
              </span>
            </template>
            <template v-slot:[`item.eway_status`]="{ item }">
              <span>
                <v-chip
                  class="ma-2"
                  :color="getColor(item.eway_status)"
                  text-color="white"
                >
                  {{ item.eway_status }}
                </v-chip>
              </span>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                @click="viewItem(item)"
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class=""
                color="green"
                >Details
              </v-btn>
            </template></v-data-table
          >
        </v-card-text>
      </v-card>
      <!-- Details Dialog -->
      <v-dialog
        v-model="detailDialog"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-card-title>
            <v-row>
              <v-col offset="11"
                ><v-btn
                  @click="detailDialog = false"
                  icon
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class=""
                  color="green"
                  >close
                </v-btn></v-col
              >
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="detailHeader"
              :items="details"
              class="table"
              mobile-breakpoint="0"
              hide-default-footer
              disable-pagination
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
// import axios from "axios";
import api from "../api";
import apiMaster from "../../../master/master-get-api";
export default {
  data() {
    return {
      searchDates: true,

      overlay: false,
      start_date_menu: false,
      end_date_menu: false,
      start_date: null,
      end_date: null,

      //
      auctionCenters: [],
      auction_center: null,
      //
      seasons: [],
      season: null,
      //
      seasonSale: [],
      seasonSales: [],
      //
      tableData: [],
      headers: [
        {
          text: "Date",
          width: 100,
          value: "sale_date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "Auction Centre",
          width: "100",
          class: "text-secondary font-weight-bolder opacity-10",
          value: "cat_data[0].auction_center_name",
          sortable: false,
        },

        {
          text: "Season",
          width: "75",
          class: "text-secondary font-weight-bolder opacity-10",
          value: "cat_data[0].season_name",
          sortable: false,
        },
        {
          text: "Sale",
          width: "75",
          class: "text-secondary font-weight-bolder opacity-10",
          value: "cat_data[0].sale_no",
          sortable: false,
        },

        {
          text: "po number",
          value: "po_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "vendor",
          value: "vendor",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "company warehouse",
          value: "company_warehouse",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "E-way",
          value: "eway_status",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "action",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
      ],
      //
      detailDialog: false,
      details: [],
      detailHeader: [
        {
          text: "LOT NO",
          width: "75",
          value: "lot_no",
          sortable: false,
        },

        {
          text: "Mark",
          width: "100",
          value: "mark_name",
          sortable: false,
        },
        {
          text: "Grade",
          width: "75",
          value: "grade_name",
          sortable: false,
        },
        {
          text: "Invoice No",
          width: "75",
          value: "invoice_no",
          sortable: false,
        },
        {
          text: "Item Code",
          width: "75",
          value: "item_name",
          sortable: false,
        },
        {
          text: "Tea Type",
          width: "75",
          value: "tea_type",
          sortable: false,
        },

        {
          text: "Net",
          width: "75",
          value: "net_kgs",
          sortable: false,
        },
        {
          text: "Bags",
          width: "75",
          value: "no_of_packages",
          sortable: false,
        },

        {
          text: "Invoice weight",
          width: "75",
          value: "total_net_kgs",
          sortable: false,
        },
        {
          text: "Purchase rate",
          width: "75",
          value: "purchased_rate",
          sortable: false,
        },
      ],
    };
  },

  async mounted() {
    await this.initialize();
    await this.searchByDates();
  },

  methods: {
    async initialize() {
      this.tableData = [];
      this.detailDialog = false;
      this.details = [];
      this.auction_center = null;
      this.seasonSale = [];
      this.season = null;

      this.auctionCenters = await apiMaster.getAuctionCenter();
      this.seasons = await apiMaster.getSeason();
      let date = new Date();
      let d = new Date(date.getFullYear(), date.getMonth(), 1);
      let d1 = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.start_date = this.formatDate(d);
      this.end_date = this.formatDate(d1);
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    switchSearch() {
      this.searchDates = !this.searchDates;
      this.clearSaleFilter();
      this.clearDateFilter();
    },
    auctionChange() {
      this.seasonSales = [];
      this.seasonSale = [];
      this.season = null;
    },
    async filterSeasonSale() {
      if (this.auction_center) {
        this.overlay = true;
        this.seasonSales = [];
        this.seasonSales =
          await apiMaster.getSeasonSaleBySeasonAndAuctionCenter(
            this.season,
            this.auction_center
          );
        this.overlay = false;
      }
    },
    clearDateFilter() {
      this.initialize();
    },
    async searchByDates() {
      this.overlay = true;
      this.tableData = [];
      this.tableData = await api.getDatewise(this.start_date, this.end_date);
      if (this.tableData.length == 0) this.nodataAlert("No Data Found...");
      this.overlay = false;
    },
    clearSaleFilter() {
      this.initialize();
      this.$refs.frm_sale_search.resetValidation();
    },
    validateSaleSearch() {
      return this.$refs.frm_sale_search.validate();
    },
    async searchBySales() {
      if (this.validateSaleSearch()) {
        this.overlay = true;
        this.tableData = [];
        this.tableData = await api.getSalewise(this.seasonSale);
        if (this.tableData.length == 0) this.nodataAlert("No Data Found...");
        this.overlay = false;
      }
    },
    getColor(status) {
      if (status == "Generated") return "rgba(105, 240, 174, 0.5)";
      else return "rgba(237, 63, 59, 0.5)";
    },
    async viewItem(item) {
      try {
        this.detailDialog = false;
        this.overlay = true;
        this.details = await api.getDetails(item.id);
        if (this.details.length > 0) {
          this.detailDialog = true;
        } else {
          this.detailDialog = false;
        }
        this.overlay = false;
      } catch (error) {}
    },
    nodataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    toggleSale() {
      this.$nextTick(() => {
        if (this.selectAllSale) {
          this.seasonSale = [];
        } else {
          this.seasonSale = this.seasonSales.slice().map((item) => {
            return item.id;
          });
        }
      });
    },
  },
  computed: {
    selectAllSale() {
      return this.seasonSales.length === this.seasonSale.length;
    },
    selectSomeSale() {
      return this.seasonSales.length > 0 && !this.selectAllSale;
    },
    iconSale() {
      if (this.selectAllSale) return "ni-fat-remove text-lg";
      if (this.selectSomeSale) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
};
</script>
