import axios from "axios";

export default {
  async getDatewise(fromDate, toDate) {
    return await axios
      .get(
        `purchase-auction/auction-history-datewise?fromDate=${fromDate}&toDate=${toDate}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getSalewise(sale_no) {
    return await axios
      .get(`purchase-auction/auction-history-sale-wise`, {
        params: {
          sale_no,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getDetails(id) {
    return await axios
      .get(`purchase-auction/auction-history-details/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
